import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appHourMask]',
})
export class HourMaskDirective {
  constructor(public el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: any): void {
    const initialValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initialValue
      .replace(/[^0-9h:]/g, '')
      .replace(/(\d{2})(\d)/, '$1h:$2')
      .slice(0, 6);
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
