import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppError } from '../../types/app-error';
import { AuthService } from '../service/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const defaultErrorMessage = { title: 'Erreur', message: "Une erreur s'est produite." };
        const errorMessages: { [key: number]: { title: string; message: string } } = {
          400: { title: 'Bad Request', message: err.error.message },
          401: { title: 'Unauthorized', message: err.error.message },
          403: {
            title: "Erreur d'autorisation",
            message: "Veuillez vérifier vos autorisations auprès d'un administrateur.",
          },
          409: { title: 'Resource Conflict', message: err.error.message },
          500: {
            title: 'Erreur de serveur interne',
            message: 'Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.',
          },
          503: {
            title: 'Erreur de connexion',
            message:
              'Impossible de se connecter au serveur. Veuillez vérifier votre connexion internet et réessayer.',
          },
          0: {
            title: 'Erreur inconnue',
            message: "Une erreur inconnue s'est produite. Veuillez réessayer ultérieurement.",
          },
        };

        let appError: AppError = {
          status: err.status,
          title: errorMessages[err.status]?.title || defaultErrorMessage.title,
          message: errorMessages[err.status]?.message || defaultErrorMessage.message,
        };

        if (err.status === 404) {
          if (err.error instanceof Blob) {
            err.error.text().then((errorMessage: string) => {
              const errorObj = JSON.parse(errorMessage);
              appError.title = errorObj.title;
              appError.message = errorObj.message;
            });
          } else {
            appError.title = 'Operation inpossible';
            appError.message = err.error.message;
          }
        }

        console.error('Erreur interceptée:', appError);
        return throwError(appError);
      }),
    );
  }
}
